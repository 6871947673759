var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showLinkAccountFields)?[_c('b-form-row',[_c('b-col',[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v("Access token")]),_c('b-form-radio-group',{attrs:{"name":"test-api-access-token-radio","aria-describedby":"access token selection"},on:{"change":_vm.onChangeIsManualAccessToken},model:{value:(_vm.formData.isManual),callback:function ($$v) {_vm.$set(_vm.formData, "isManual", $$v)},expression:"formData.isManual"}},[_c('b-form-radio',{attrs:{"value":true}},[_vm._v("Manual ")]),_c('b-form-radio',{attrs:{"value":false}},[_vm._v("User based")])],1)],1)],1)],1),(_vm.formData.isManual)?_c('b-form-row',[_c('b-col',[_c('b-form-group',[_c('div',{staticClass:"floating-input-field"},[_c('b-form-input',{class:{
                'is-invalid': !_vm.formData.accessToken && _vm.formSubmitted,
              },attrs:{"id":"access-token","type":"text","placeholder":" ","required":""},model:{value:(_vm.formData.accessToken),callback:function ($$v) {_vm.$set(_vm.formData, "accessToken", $$v)},expression:"formData.accessToken"}}),_c('label',{attrs:{"for":"access-token"}},[_vm._v("Access token")]),(!_vm.formData.accessToken && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Access token required.")]):_vm._e()],1)])],1)],1):_vm._e()]:_vm._e(),(!_vm.formData.isManual)?[(_vm.showLinkedAccountErrorMsg)?_c('b-form-row',[_c('b-col',[_c('h6',{staticClass:"font-14",attrs:{"id":"linked-user-error-msg"}},[_c('InfoCircelYellow',{staticClass:"mr-1 mt-n1"}),_vm._v(" "+_vm._s(_vm.getLinkedAccountErrorMessage)+" ")],1)])],1):_vm._e(),_c('b-form-row',[_c('b-col',[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('v-select',{class:{
                'is-value-exist': _vm.formData.userId || _vm.focusedUser,
                'is-invalid': !_vm.formData.userId && _vm.formSubmitted,
              },attrs:{"label":"email","id":"linked-user-email","reduce":(email) => email.userId,"disabled":!_vm.linkedAccountUsersList.length && !_vm.userSearchTerm,"options":_vm.linkedAccountUsersList},on:{"open":function($event){_vm.focusedUser = true},"close":function($event){_vm.focusedUser = false},"search":_vm.onSearchUsers,"input":_vm.getSelectedAddress},scopedSlots:_vm._u([{key:"no-options",fn:function({ search }){return [_vm._v(" "+_vm._s(search ? `No users found.` : `Start typing user, and select it from the dropdown.`)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.email)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.email)+" ")]}}],null,false,3775393560),model:{value:(_vm.formData.userId),callback:function ($$v) {_vm.$set(_vm.formData, "userId", $$v)},expression:"formData.userId"}}),_c('label',{attrs:{"for":"linked-user-email"}},[_vm._v("Select user")]),(!_vm.formData.userId && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("User required.")]):_vm._e()],1)])],1)],1),(_vm.data.isPropertyDependent)?_c('b-form-row',[_c('b-col',[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('v-select',{class:{
                'is-value-exist': _vm.formData.selectedAddress,
                'is-invalid': !_vm.formData.selectedAddress && _vm.formSubmitted,
              },attrs:{"label":"fullAddress","id":"linked-user-address","disabled":!_vm.formData.userId || !_vm.linkedAccountUserAddressesList.length,"clear-search-on-blur":(clearSearchOnSelect, multiple) => clearSearchOnSelect && !multiple,"options":_vm.linkedAccountUserAddressesList,"reduce":(fullAddress) => fullAddress},scopedSlots:_vm._u([{key:"no-options",fn:function({ search }){return [_vm._v(" "+_vm._s(search ? `No address found.` : `Start typing address, and select it from the dropdown.`)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullAddress)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullAddress)+" ")]}}],null,false,805795851),model:{value:(_vm.formData.selectedAddress),callback:function ($$v) {_vm.$set(_vm.formData, "selectedAddress", $$v)},expression:"formData.selectedAddress"}}),_c('label',{attrs:{"for":"linked-user-address"}},[_vm._v("Select address")]),(_vm.showFullAddressErrorMsg)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.getLinkedAddressError))]):_vm._e(),(!_vm.formData.selectedAddress && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Address required.")]):_vm._e()],1)])],1)],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }