<template>
  <div>
    <template v-if="showLinkAccountFields">
      <b-form-row>
        <b-col>
          <b-form-group>
            <h4 class="page-sub-header">Access token</h4>
            <b-form-radio-group
              v-model="formData.isManual"
              name="test-api-access-token-radio"
              @change="onChangeIsManualAccessToken"
              aria-describedby="access token selection"
            >
              <b-form-radio :value="true">Manual </b-form-radio>
              <b-form-radio :value="false">User based</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="formData.isManual">
        <b-col>
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                id="access-token"
                :class="{
                  'is-invalid': !formData.accessToken && formSubmitted,
                }"
                type="text"
                placeholder=" "
                v-model="formData.accessToken"
                required
              ></b-form-input>
              <label for="access-token">Access token</label>
              <b-form-invalid-feedback class="d-block" v-if="!formData.accessToken && formSubmitted">Access token required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
    <template v-if="!formData.isManual">
      <b-form-row v-if="showLinkedAccountErrorMsg">
        <b-col>
          <h6 class="font-14" id="linked-user-error-msg">
            <InfoCircelYellow class="mr-1 mt-n1" />
            {{ getLinkedAccountErrorMessage }}
          </h6>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <div class="floating-select-field">
              <v-select
                label="email"
                id="linked-user-email"
                :reduce="(email) => email.userId"
                :disabled="!linkedAccountUsersList.length && !userSearchTerm"
                :options="linkedAccountUsersList"
                v-model="formData.userId"
                @open="focusedUser = true"
                @close="focusedUser = false"
                @search="onSearchUsers"
                @input="getSelectedAddress"
                :class="{
                  'is-value-exist': formData.userId || focusedUser,
                  'is-invalid': !formData.userId && formSubmitted,
                }"
              >
                <template slot="no-options" slot-scope="{ search }">
                  {{
                    search
                      ? `No users found.`
                      : `Start typing user, and select it from the
                      dropdown.`
                  }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.email }}
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.email }}
                </template>
              </v-select>
              <label for="linked-user-email">Select user</label>
              <b-form-invalid-feedback class="d-block" v-if="!formData.userId && formSubmitted">User required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="data.isPropertyDependent">
        <b-col>
          <b-form-group>
            <div class="floating-select-field">
              <v-select
                label="fullAddress"
                id="linked-user-address"
                :disabled="!formData.userId || !linkedAccountUserAddressesList.length"
                :clear-search-on-blur="(clearSearchOnSelect, multiple) => clearSearchOnSelect && !multiple"
                :options="linkedAccountUserAddressesList"
                :reduce="(fullAddress) => fullAddress"
                v-model="formData.selectedAddress"
                :class="{
                  'is-value-exist': formData.selectedAddress,
                  'is-invalid': !formData.selectedAddress && formSubmitted,
                }"
              >
                <template slot="no-options" slot-scope="{ search }">
                  {{
                    search
                      ? `No address found.`
                      : `Start typing address, and select it from the
                      dropdown.`
                  }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.fullAddress }}
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.fullAddress }}
                </template>
              </v-select>
              <label for="linked-user-address">Select address</label>
              <b-form-invalid-feedback class="d-block" v-if="showFullAddressErrorMsg">{{ getLinkedAddressError }}</b-form-invalid-feedback>
              <b-form-invalid-feedback class="d-block" v-if="!formData.selectedAddress && formSubmitted">Address required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import InfoCircelYellow from '@/assets/svg/info-circle-yellow.svg'
import { AUTHENTICATION_METHODS_ENUMS } from '@/utilities/widget-constants'
export default {
  name: 'AccessToeknForm',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formSubmitted: false,
      formData: {
        isManual: true,
        accessToken: null,
        userId: null,
        selectedAddress: null,
      },
      linkAccountDetails: null,
      showLinkedAccountErrorMsg: false,
      linkedAccountUsersList: [],
      focusedUser: false,
      userSearchTerm: '',
      linkedAccountUserAddressesList: [],
      showFullAddressErrorMsg: false,
    }
  },
  components: {
    InfoCircelYellow,
  },
  computed: {
    showLinkAccountFields() {
      return this.data.authenticationType === AUTHENTICATION_METHODS_ENUMS.LINK_ACCOUNT
    },
    getLinkedAccountErrorMessage() {
      return this.showLinkAccountFields ? DISPLAY_MESSAGES.TEST_API_LINKED_USERLIST_ERROR : DISPLAY_MESSAGES.TEST_API_LINKED_USERLIST_IDENTITY_PROVIDER_ERROR
    },
    getLinkedAddressError() {
      return DISPLAY_MESSAGES.TEST_API_LINKED_ADDRESS_ERROR
    },
  },
  mounted() {
    this.assginData()
  },
  methods: {
    assginData() {
      this.formData.isManual = this.showLinkAccountFields
      this.$emit('isManualAccessToekn', this.formData.isManual)
      if (this.showLinkAccountFields) {
        this.linkAccountDetails = this.data.linkAccountDetails
      } else {
        this.linkAccountDetails = { id: 0, authenticationType: this.data.authenticationType }
        this.getSelectedLinkedAccountUsers()
      }
    },
    onChangeIsManualAccessToken() {
      this.getSelectedLinkedAccountUsers()
      this.$emit('isManualAccessToekn', this.formData.isManual)
    },
    onSearchUsers(search, loading) {
      this.userSearchTerm = search
      loading(true)
      this.searchUsersList(loading, search, this)
    },
    searchUsersList: debounce(async (loading, search, vm) => {
      await vm.getSelectedLinkedAccountUsers(search)
      loading(false)
    }, 50),
    async getSelectedLinkedAccountUsers(searchTerm = '') {
      if (this.linkAccountDetails && !this.formData.isManual) {
        const postObj = {
          linkAccountId: this.linkAccountDetails.id,
          authenticationType: this.linkAccountDetails.authenticationType,
          searchTerm: searchTerm,
        }
        this.$store
          .dispatch('widgets/getLinkedAccountUsers', postObj)
          .then((res) => {
            this.linkedAccountUsersList = res.data
            this.showLinkedAccountErrorMsg = !!(!searchTerm && !res.data.length)
          })
          .catch(() => {
            this.showLinkedAccountErrorMsg = false
            this.linkedAccountUsersList = []
          })
      }
    },
    getSelectedAddress() {
      if (this.data.isPropertyDependent) {
        this.formData.selectedAddress = null
        this.linkedAccountUserAddressesList = []
        const postObj = {
          userId: this.formData.userId,
          linkAccountId: this.linkAccountDetails.id,
          authenticationType: this.linkAccountDetails.authenticationType,
          isForEachProperty: !!this.linkAccountDetails.isForEachProperty,
        }
        if (this.formData.userId) {
          this.$store
            .dispatch('widgets/getLinkedAccountUserAddresses', postObj)
            .then((res) => {
              this.linkedAccountUserAddressesList = res.data
              this.showFullAddressErrorMsg = !res.data.length
            })
            .catch(() => {
              this.showFullAddressErrorMsg = false
              this.linkedAccountUserAddressesList = []
            })
        } else {
          this.linkedAccountUserAddressesList = []
        }
      }
    },
    validateForm() {
      this.formSubmitted = true
      const accessToken = this.showLinkAccountFields && this.formData.isManual ? !!this.formData.accessToken : true
      const userId = !this.formData.isManual ? !!this.formData.userId : true
      const selectedAddress = !this.formData.isManual && this.data.isPropertyDependent ? !!this.formData.selectedAddress : true
      const result = accessToken && userId && selectedAddress
      return result ? this.formData : result
    },
    resetForm() {
      this.formSubmitted = false
      this.formData = {
        isManual: true,
        accessToken: null,
        userId: null,
        selectedAddress: null,
      }
      this.onChangeIsManualAccessToken()
    },
  },
}
</script>
